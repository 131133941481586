<template>
  <VCardText class="d-flex justify-center">
    <VSheet class="d-flex justify-center">
      <VList
        nav
        class="pa-0"
      >
        <VListItemGroup v-model="currentSelector">
          <VListItem
            v-for="selector in selectors"
            :key="selector.key"
            class="d-flex justify-center align-center pr-0 py-0 my-0"
            link
          >
            <span v-text="selector.short" />
            <VTooltip right>
              <template #activator="{ on }">
                <VImg
                  :src="require(`@/assets/icons/SVG/${selector.icon}`)"
                  class="ml-1"
                  width="2rem"
                  height="2rem"
                  v-on="on"
                />
              </template>
              <span>{{ $t(selector.title) }}</span>
            </VTooltip>
          </VListItem>
        </VListItemGroup>
      </VList>
    </VSheet>
  </VCardText>
</template>

<script>
import { getInventory } from '@/services/measurements'
import selectors from '@/utils/data/selectors.json'
import { mapState } from 'vuex'
import vuexMixin from '@/mixins/vuexMixin'

export default {
  name: 'MSSelector',

  mixins: [vuexMixin],

  data: () => ({
    selectors,
    currentSelector: null,
    inventory: []
  }),

  computed: {
    ...mapState({
      measurements: ({ measurements }) => measurements,
      loading: ({ measurements }) => measurements.loading,
      filter: ({ measurements }) => measurements.checkedAssets?.queryString
    }),

    filterSelector () {
      return `${this.filter}|${this.currentSelector}`
    }
  },

  watch: {
    filterSelector () {
      this.updateAssets()
    },

    currentSelector () {
      this.setVuexElement({
        path: 'measurements.elementDetails',
        value: {
          title: null,
          info: {}
        }
      })
    }
  },

  methods: {
    storeInventory () {
      const selector = this.selectors[this.currentSelector]

      this.setVuexElement({
        path: 'measurements',
        value: {
          ...this.measurements,
          inventory: this.inventory,
          ...(selector && { currentSelector: selector })
        }
      })
    },

    toggleLoading () {
      this.setVuexElement({
        path: 'measurements.loading',
        value: !this.loading
      })
    },

    async updateAssets () {
      this.setVuexElement({
        path: 'measurements.timeSelection',
        value: {
          Measurements: { start: null, end: null },
          Events: { start: null, end: null }
        }
      })

      const selector = this.selectors[this.currentSelector]

      if (selector && this.filter) {
        this.toggleLoading()

        getInventory(selector.value, this.filter, selector.source).then(
          (inventory) => {
            this.inventory = inventory
            this.storeInventory()
            this.toggleLoading()
          }
        )
      } else {
        this.inventory = []
        this.storeInventory()
      }
    }
  }
}
</script>
